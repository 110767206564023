.ReactTags__tagInputField {
    @apply bg-transparent w-full py-2 outline-none;
}
.ReactTags__selected {
    @apply flex flex-wrap py-2 gap-4;
}

.ReactTags__selected span {
    @apply bg-purple-200  py-1 px-4 rounded-full;
}

.ReactTags__tags {
    @apply border-[1px] px-4 py-2 rounded-xl;
}
.ReactTags__suggestions {
    @apply lowercase;
}
