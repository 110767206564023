

.card {
    @apply rounded-lg shadow-2xl bg-white dark:bg-gray-800 ;
}

.line {
    @apply h-[1px] my-2 bg-gray-300 rounded-full dark:bg-darkBgTertiary;
}


@keyframes ltr {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

@keyframes rtl {
    0% {
        left: 0%;
    }
    100% {
        left: -100%;
    }
}

.open-left-to-right-drawer {
    animation: ltr 2.5s ease-in-out;
}

.open-left-to-right-drawer.hidden {
    animation: ltr 1.1s ease-in-out;
}


/*.no-scrollbar::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/