.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */

    border-radius: 50%;
    width: 26px;
    height: 26px;
    animation: spin 1s linear infinite;
    @apply border-t-brand-700 ;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}